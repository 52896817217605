<template>
    <section class="section section--medium map">
        <div class="container">
            <div class="map__columns">
                <div class="map__column">
                    <div class="map__phone-mockup">
<!--                        <video width="232" height="471" src="./../assets/videos/phone_1920.mp4" autoplay muted loop></video>-->
                        <img width="232" height="471" src="./../assets/images/map/phone_232x471_8-optimized_2.gif" alt="">
                    </div>
                </div>

                <div class="map__column map__column--shadow">
                    <h3 class="title title--large map__title">
                        <a target="_blank" href="https://maphome.ru">Единая база собственников</a>
                    </h3>
                </div>
            </div>
        </div>

        <picture class="cover">
            <source srcset="../assets/images/map/375@1x.jpg" media="(max-width: 600px)">
            <source srcset="../assets/images/map/1024@1x.jpg" media="(max-width: 960px)">
            <img class="cover__image" src="./../assets/images/map/1400@1x.jpg" alt="">
        </picture>
    </section>
</template>

<script>
export default {
    name: "AppMap"
}
</script>

<style lang="scss">

.map {
    position: relative;
    border-top: 100px solid #1F1F1F;

    &__title {
        & a,
        & a:hover {
            color: var(--color-black);
        }
    }

    &__columns {
        display: flex;
    }

    &__column {

        &--shadow {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 0 0 60px;
            max-width: 670px;
            text-align: center;
            background: radial-gradient(
                    ellipse at center,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, .8) 50%,
                    rgba(255, 255, 255, .05) 70%,
                    rgba(255, 255, 255, 0) 100%
            );
        }
    }

    &__phone-mockup {
        margin: -200px 60px 0;
    }

    @media all and (max-width: 960px) {
        border-top: 300px solid #1F1F1F;

        &__columns {
            flex-flow: column nowrap;
            align-items: center;
        }

        &__phone-mockup {
            margin: -420px 0 0;
        }

        &__title {
            padding: 70px 0;
        }
    }

    @media all and (max-width: 600px) {
        padding-bottom: 0 !important;
        border-top: 250px solid #1F1F1F;

        &__phone-mockup {
            margin: -300px 0 0;
        }

        &__column {

            &--shadow {
                margin: 60px 0 0;
            }
        }
    }
}
</style>