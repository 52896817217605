<template>
    <div class="order-success">
        <div class="media">
            <img class="media__image" width="240" height="180" :src="src + '?rand=' + Math.random()" alt="">
        </div>

        <h4 class="order-success__title">Спасибо! <br>Ваша заявка принята</h4>
        <p class="order-success__subtitle">Мы свяжемся с вами в ближайшее время</p>
    </div>
</template>

<script>
export default {
    name: "AppFormSent",

    computed: {
        src() {
            return require('@/assets/images/order/success.gif')
        }
    }
}
</script>

<style lang="scss">
.order-success {
    text-align: center;

    &__title {
        font-weight: 700;
        font-size: var(--size-3);
        margin: 0 0 15px;
    }

    &__subtitle {
        font-size: var(--size-6);
    }
}

.media {

    &__image {
        margin: 0 auto;
    }
}
</style>