<template>
    <header v-click-outside="close" class="navbar">
        <div class="container navbar__container">
            <div class="navbar__left">
                <img class="navbar__logo" alt="PACR" src="./../assets/images/logo.svg">
                <a class="navbar__logo-link" href="https://maphome.ru" target="_blank"></a>
            </div>

            <div class="navbar__center">
                <div :class="['navbar__nav-container', {'navbar__nav-container--opened': navbar.opened}]">
                    <a @click.prevent="toggle" class="navbar__close" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 23 23">
                            <path stroke="#231F20" stroke-miterlimit="10" stroke-width="2" d="m21.613 1.613-20 20m20 0-20-20"/>
                        </svg>
                    </a>

                    <nav class="navbar__nav">
                        <div class="navbar__nav-list">
                            <a @click="close" class="navbar__nav-item" href="#counters">Об объединении</a>
                            <a @click="close" class="navbar__nav-item" href="#partners">Участники</a>
                        </div>

                        <div class="navbar__nav-list">
                            <a @click="close" class="navbar__nav-item" href="#map">База собственников</a>
                            <a @click="close" class="navbar__nav-item" href="#cabinet">Инструментарий</a>
                        </div>

                        <div class="navbar__nav-list">
                            <a class="navbar__nav-item navbar__nav-item--active" href="/register.html">
                                Вступить в сообщество
                            </a>
                            <a class="navbar__nav-item navbar__nav-item--active" href="#" @click.prevent="emitter.emit('modal.request:open')">
                                Задействовать сообщество
                            </a>
                            <a class="navbar__nav-item navbar__nav-item--active navbar__nav-item--touch" href="/login.html">
                                <svg class="navbar__nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
                                    <path fill="#00ADEE" d="M20.747 0h2.606c.1 0 .2.1.3.1 3.409.2 6.616 1.199 9.623 2.997 5.713 3.496 9.22 8.491 10.423 15.085.2.8.2 1.698.301 2.497v2.698c0 .1-.1.2-.1.3-.2 1.098-.3 2.297-.501 3.396-1.103 4.695-3.508 8.492-7.217 11.688a22.403 22.403 0 0 1-4.41 2.897c-4.91 2.398-10.023 2.998-15.335 1.599-5.011-1.299-9.12-4.096-12.227-8.292C1.002 30.37-.4 25.275.1 19.68c.501-4.595 2.305-8.69 5.413-12.187C8.92 3.696 13.13 1.299 18.24.4c.802-.2 1.604-.3 2.506-.4Zm14.834 37.163c.601-.6 1.203-1.199 1.804-1.898 4.61-5.495 6.114-11.788 4.21-18.681C38.787 6.194 28.564-.1 17.94 1.998 7.517 4.096.3 13.886 1.804 24.576c.601 4.595 2.606 8.491 5.813 11.788.702.7.702.7 1.504.2 0 0 .1-.1.2-.1 1.905-1.1 3.91-2.098 5.813-3.197.802-.4 1.103-1 1.103-1.898V29.67c0-.3-.1-.5-.2-.7-.903-1.398-1.704-2.797-2.105-4.395l-.301-.6c-.501-.5-.802-1.099-.802-1.798 0-1-.1-2.098 0-3.097 0-.5.3-.999.501-1.598.1-.2.2-.4.2-.6v-3.896c0-2.497 1.404-4.895 3.81-5.794 3.006-1.199 6.013-1.199 9.02-.2 2.907.999 4.41 3.297 4.41 6.394v3.596c0 .2.1.3.2.5.2.5.502.999.502 1.498.1 1 0 2.098 0 3.097 0 .999-.502 1.698-1.303 2.298-.2.1-.301.3-.401.5-.502.998-.802 1.997-1.404 2.896-.902 1.1-1.102 2.298-1.002 3.697.1.799.401 1.498 1.103 1.798 2.205 1.398 4.61 2.597 7.116 3.896Zm-1.403.999c-.1-.1-.301-.2-.401-.2-2.105-.999-4.21-2.098-6.214-3.097-1.403-.699-2.005-1.798-2.005-3.296V29.17c0-.2.1-.4.2-.5 1.203-1.498 2.005-3.096 2.607-4.895.1-.2.2-.4.4-.5.702-.199 1.003-.698 1.003-1.398V19.28c0-.4-.301-.7-.401-1.1-.2-.199-.3-.399-.3-.599v-4.395c0-2.098-1.103-3.796-3.008-4.496-2.506-.999-5.111-.999-7.617-.1-2.205.7-3.408 2.498-3.408 4.696v4.495c0 .2-.1.4-.2.5-.301.3-.501.7-.501 1.099v2.597c0 .5.2.9.6 1.199.101.1.302.3.302.5a15.378 15.378 0 0 0 2.405 4.995c.1.1.2.3.2.499v2.298c0 1.498-.6 2.597-2.004 3.396-1.904 1-3.809 2.098-5.713 3.097-.1 0-.3.1-.4.2 7.516 5.794 17.94 5.195 24.455 0Z"/>
                                </svg>
                                <span class="navbar__nav-item-text">Личный кабинет</span>
                            </a>
                        </div>
                    </nav>

                    <a class="navbar__phone" href="tel:79263338803">+7 926 333-88-03</a>
                    <a href="https://api.whatsapp.com/send?phone=79263338803" class="navbar__whatsapp">Написать в </a>
                </div>
            </div>

            <div class="navbar__right">
                <a class="navbar__icon navbar__icon--user" href="/login.html">
                    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
                        <path fill="#00ADEE" d="M20.747 0h2.606c.1 0 .2.1.3.1 3.409.2 6.616 1.199 9.623 2.997 5.713 3.496 9.22 8.491 10.423 15.085.2.8.2 1.698.301 2.497v2.698c0 .1-.1.2-.1.3-.2 1.098-.3 2.297-.501 3.396-1.103 4.695-3.508 8.492-7.217 11.688a22.403 22.403 0 0 1-4.41 2.897c-4.91 2.398-10.023 2.998-15.335 1.599-5.011-1.299-9.12-4.096-12.227-8.292C1.002 30.37-.4 25.275.1 19.68c.501-4.595 2.305-8.69 5.413-12.187C8.92 3.696 13.13 1.299 18.24.4c.802-.2 1.604-.3 2.506-.4Zm14.834 37.163c.601-.6 1.203-1.199 1.804-1.898 4.61-5.495 6.114-11.788 4.21-18.681C38.787 6.194 28.564-.1 17.94 1.998 7.517 4.096.3 13.886 1.804 24.576c.601 4.595 2.606 8.491 5.813 11.788.702.7.702.7 1.504.2 0 0 .1-.1.2-.1 1.905-1.1 3.91-2.098 5.813-3.197.802-.4 1.103-1 1.103-1.898V29.67c0-.3-.1-.5-.2-.7-.903-1.398-1.704-2.797-2.105-4.395l-.301-.6c-.501-.5-.802-1.099-.802-1.798 0-1-.1-2.098 0-3.097 0-.5.3-.999.501-1.598.1-.2.2-.4.2-.6v-3.896c0-2.497 1.404-4.895 3.81-5.794 3.006-1.199 6.013-1.199 9.02-.2 2.907.999 4.41 3.297 4.41 6.394v3.596c0 .2.1.3.2.5.2.5.502.999.502 1.498.1 1 0 2.098 0 3.097 0 .999-.502 1.698-1.303 2.298-.2.1-.301.3-.401.5-.502.998-.802 1.997-1.404 2.896-.902 1.1-1.102 2.298-1.002 3.697.1.799.401 1.498 1.103 1.798 2.205 1.398 4.61 2.597 7.116 3.896Zm-1.403.999c-.1-.1-.301-.2-.401-.2-2.105-.999-4.21-2.098-6.214-3.097-1.403-.699-2.005-1.798-2.005-3.296V29.17c0-.2.1-.4.2-.5 1.203-1.498 2.005-3.096 2.607-4.895.1-.2.2-.4.4-.5.702-.199 1.003-.698 1.003-1.398V19.28c0-.4-.301-.7-.401-1.1-.2-.199-.3-.399-.3-.599v-4.395c0-2.098-1.103-3.796-3.008-4.496-2.506-.999-5.111-.999-7.617-.1-2.205.7-3.408 2.498-3.408 4.696v4.495c0 .2-.1.4-.2.5-.301.3-.501.7-.501 1.099v2.597c0 .5.2.9.6 1.199.101.1.302.3.302.5a15.378 15.378 0 0 0 2.405 4.995c.1.1.2.3.2.499v2.298c0 1.498-.6 2.597-2.004 3.396-1.904 1-3.809 2.098-5.713 3.097-.1 0-.3.1-.4.2 7.516 5.794 17.94 5.195 24.455 0Z"/>
                    </svg>
                </a>

                <a @click.prevent="toggle" class="navbar__icon navbar__icon--burger" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" fill="none" viewBox="0 0 32 25">
                        <path fill="#fff" d="M32 0H0v2h32V0Zm0 11.2H0v2h32v-2Zm0 11.3H0v2h32v-2Z"/>
                    </svg>
                </a>
            </div>
        </div>
    </header>
</template>

<script>

import {clickOutside} from "@/directives.js";

export default {
    name: "AppHeader",
    directives: {
        clickOutside
    },

    data() {
        return {
            navbar: {
                opened: false,
            }
        }
    },

    methods: {
        toggle() {
            this.navbar.opened = !this.navbar.opened;
        },

        close() {
            this.navbar.opened = false;
        }
    }
}
</script>

<style lang="scss">

.navbar {
    padding: 0 30px;
    background-color: #1F1F1F;

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 140px;
    }

    &__left {
        position: relative;
        flex-grow: 1;
    }

    &__right {
        margin: 0 0 0 30px;
    }

    &__logo-link {
        position: absolute;
        width: 160px;
        height: 100%;
        left: 200px;
    }

    &__nav {
        display: flex;
        flex-flow: row wrap;
    }

    &__nav-item {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        color: var(--grey-light);
        font-size: 18px;
        white-space: nowrap;

        &--active,
        &:hover {
            color: var(--color-primary);
        }

        &--touch {
            display: none;
        }
    }

    &__nav-item-icon {
        width: 16px;
        height: auto;
        margin-right: 6px;
    }

    &__icon {
        &--burger {
            display: none;
        }
    }

    &__close {
        display: none;
        position: absolute;
        padding: 10px;
        right: 15px;
        top: 15px;
    }

    &__phone {
        display: none;
        margin: 40px 0;
        font-size: var(--size-3);
        color: #231F20;
    }

    &__whatsapp {
        display: none;
        align-items: center;
        justify-content: space-between;
        background-color: #00bd45;
        white-space: nowrap;
        border-radius: 9999px;
        transition: all .3s;
        padding: 0 0 0 20px;

        &, &:hover {
            color: var(--color-white);
        }

        &:after {
            content: "";
            display: inline-flex;
            margin: 0 0 0 10px;
            width: 42px;
            height: 42px;
            background: url("./../assets/images/whatsap.svg") no-repeat;
            box-shadow: 0 0 12px rgba(0, 0, 0, .4);
            border-radius: 99999px;
        }
    }

    @media all and (max-width: 1200px) {
        &__container {
            height: 88px;
        }

        &__logo {
            max-width: 260px;
        }

        &__logo-link {
            left: 140px;
        }

        &__nav-item {
            padding: 2px 8px;
            font-size: 16px;
        }
    }

    @media all and (max-width: 960px) {
        &__nav-container {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            position: fixed;
            top: 0;
            right: 0;
            padding: 60px 40px;
            width: 340px;
            max-height: 100%;
            background-color: var(--color-white);
            border-radius: 17px 0 0 17px;
            transform: translateX(100%);
            transition: transform .3s;
            overflow: auto;
            z-index: 9;

            &--opened {
                transform: translateX(0);
                box-shadow: 0 0 10px rgba(0, 0, 0, .25);
            }
        }

        &__nav-item {
            color: var(--color-black);
            font-size: var(--size-5);
            margin: 0 0 10px;

            &--active {
                color: var(--color-primary);
            }

            &--touch {
                display: flex;
            }
        }

        &__close,
        &__phone {
            display: block;
        }

        &__whatsapp {
            display: flex;
        }

        &__icon {
            &--user {
                display: none;
            }

            &--burger {
                display: flex;
            }
        }
    }

    @media all and (max-width: 600px) {

        &__container {
            height: 60px;
        }

        &__logo {
            max-width: 160px;
        }

        &__logo-link {
            left: 90px;
            width: 80px;
        }

        &__nav-item {
            font-size: var(--size-7);
        }
    }
}
</style>