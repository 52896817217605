<template>
    <div class="section section--medium about">
        <div class="container">
            <h1 :class="['title title--large title--light about__title', {'about__title--unmuted': !muted}]">
                Что такое брокер-тур?
            </h1>

            <button @click.prevent="toggleSound" type="button" class="button button--muted">
                <svg v-if="muted" class="about__speaker" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path fill="#fff" d="M4.34 2.93L2.93 4.34 7.29 8.7 7 9H3v6h4l5 5v-6.59l4.18 4.18c-.65.49-1.38.88-2.18 1.11v2.06a8.94 8.94 0 003.61-1.75l2.05 2.05 1.41-1.41L4.34 2.93zM10 15.17L7.83 13H5v-2h2.83l.88-.88L10 11.41v3.76zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zm-7-8l-1.88 1.88L12 7.76V4zm4.5 8A4.5 4.5 0 0014 7.97v1.79l2.48 2.48c.01-.08.02-.16.02-.24z"/>
                </svg>

                <svg v-else class="about__speaker" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path fill="#fff" d="M3 9v6h4l5 5V4L7 9H3Zm13.5 3A4.5 4.5 0 0 0 14 7.97v8.05c1.48-.73 2.5-2.25 2.5-4.02ZM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77Z"/>
                </svg>

                <span class="about__speaker-text">{{ this.muted ? 'Включить звук' : 'Выключить звук' }}</span>
            </button>
        </div>

        <div class="video-cover">
            <video ref="video" :src="video.src" :muted="muted" playsinline autoplay loop></video>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppAbout",

    data() {
        return {
            muted: true,
            video: {
                src: require('@/assets/videos/how-it-works.mp4') + '#t=40'
            }
        }
    },

    methods: {
        toggleSound() {
            this.muted = !this.muted;

            if (!this.muted) {
                this.$refs.video.currentTime = 0;
            }
        }
    }
}
</script>

<style lang="scss">

.about {
    overflow: hidden;

    &__title {
        max-width: 350px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, .3);
        margin-bottom: -100px;
    }

    &__speaker-text {
        padding-left: 10px;
    }

    .video-cover {
        position: relative;
        padding-bottom: 51%;
        margin-left: -30px;
        margin-right: -30px;
        z-index: -1;
        transform: scale(1.2);

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @media all and (max-width: 960px) {
        .video-cover {
            padding-bottom: 56.25%;
        }
    }

    @media all and (max-width: 600px) {

        &__title {
            font-size: 20px;
            max-width: 200px;
            margin-bottom: -80px;

            &--unmuted {
                visibility: hidden;
            }
        }

        .video-cover {
            padding-bottom: 70%;
            transform: scale(1.4);
        }

        &__speaker-text {
            display: none;
        }
    }
}
</style>