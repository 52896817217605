<template>
    <div class="section section--medium counters">
        <div class="container">
            <h3 class="title counters__title">Об объединении</h3>

            <div ref="list" class="counters__list">
                <div class="counters__item" :key="index" v-for="(item, index) in items">
                    <span class="counters__value">
                        <number animationPaused :ref="`counter-${index}`" :from="item.number.from" :to="item.number.to" :duration="item.number.duration"></number><!--
                        -->{{ item.number.after }}
                    </span>
                    <span class="counters__label">{{ item.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Number from 'vue-number-animation/Number.vue'

export default {
    name: "AppCounters",
    components: {Number},

    data() {
        return {
            items: [{
                label: 'Агентств недвижимости',
                number: {to: 100, duration: 2, from: 0, after: '+'}
            }, {
                label: 'Загородных экспертов',
                number: {to: 1700, duration: 2, from: 0, after: '+'}
            }, {
                label: 'Партнёрских сделок',
                number: {to: 80, duration: 0, from: 80, after: '%'}
            }, {
                label: 'Год основания',
                number: {to: 2019, duration: 5, from: new Date().getFullYear(), after: ''}
            }]
        }
    },

    mounted() {
        const observer = new IntersectionObserver((entries) => entries.forEach(entry => {
            if (entry.isIntersecting) {
                Object.keys(this.$refs)
                    .filter(name => name.indexOf('counter-') !== -1)
                    .forEach(ref => this.$refs[ref][0].play());

                observer.disconnect();
            }
        }), {});

        observer.observe(this.$refs.list);
    }
}
</script>

<style lang="scss">

.counters {

    &__title {
        margin: 0 0 80px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex-flow: column nowrap;
        width: 25%;
    }

    &__value {
        color: var(--color-primary);
        font-size: 82px;
        font-weight: 700;
        line-height: 1;
    }

    &__label {
        color: var(--grey-dark);
    }

    @media all and (max-width: 1200px) {
        &__value {
            font-size: 65px;
        }
    }

    @media all and (max-width: 960px) {
        &__list {
            flex-flow: row wrap;
            justify-content: flex-start;
            margin-bottom: -80px;
        }

        &__item {
            width: 50%;
            margin: 0 0 80px;
        }
    }

    @media all and (max-width: 600px) {
        &__title {
            margin: 0 0 60px;
        }

        &__list {
            justify-content: space-between;
        }

        &__item {
            width: 45%;
        }

        &__value {
            font-size: 38px;
        }
    }
}
</style>