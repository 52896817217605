import {createApp} from 'vue'
import App from './App.vue'

import mitt from 'mitt';

/**
 * Initialize emitter
 *
 * @type {Emitter}
 */
const emitter = mitt();

/**
 * Create app
 * @type {App<Element>}
 */
const app = createApp(App);

app.config.globalProperties.emitter = emitter;

/**
 * Mount app
 */
app.mount('#app');