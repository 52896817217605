<template>
    <section class="section section--medium section--dark cabinet-info">
        <div class="container">
            <div class="cabinet-info__columns">
                <div class="cabinet-info__column cabinet-info__column--content">
                    <p>Участники нашего сообщества получают доступ к сервису с широким набором вспомогательных функций для эффективной работы и дополнительного заработка.</p>
                    <p>Доступ к сервису бесплатный и предоставляется автоматически каждому участнику.</p>
                </div>

                <div class="cabinet-info__column cabinet-info__column--buttons">
                    <a href="/login.html" class="button cabinet-info__button">Вход в личный кабинет</a>
                    <a href="/register.html" class="button button--primary button--outlined cabinet-info__button">
                        Подать заявку на вступление
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AppCabinetInfo"
}
</script>

<style lang="scss">

.cabinet-info {

    &__columns {
        display: flex;
        justify-content: space-around;
    }

    &__column {

        &--content {
            max-width: 56%;
            color: var(--color-white);

            p {
                margin-bottom: 20px;
            }
        }

        &--buttons {
            max-width: 320px;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: 20px;
    }

    @media all and (max-width: 960px) {
        &__columns {
            flex-flow: column nowrap;
        }

        &__column {
            max-width: 100%;

            &--buttons {
                display: flex;
                justify-content: space-between;
                margin: 40px 0 0;
            }
        }

        &__button {
            max-width: 48%;
        }
    }

    @media all and (max-width: 960px) {
        &__column {

            &--buttons {
                flex-flow: column nowrap;
            }
        }

        &__button {
            max-width: 100%;
        }
    }
}
</style>