/**
 * This file contains Vue Directives.
 */

/**
 * On click outside element directive.
 *
 * @type {{unmount: clickOutside.unmount, mounted: clickOutside.mounted}}
 */
const clickOutside = {
    mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };

        document.body.addEventListener('click', el.clickOutsideEvent)
    },

    unmount: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
};

export {
    clickOutside
};
