<template>
    <div class="section hero">
        <div class="container">
            <p class="hero__suptitle">Профессиональное объединение загородных риелторов</p>
            <h1 class="hero__title">
                Единый инструмент <span><br>для эффективной работы <br>с загородной недвижимостью</span>
            </h1>
        </div>

        <picture class="cover">
            <source srcset="../assets/images/hero/600@1x.jpg" media="(max-width: 600px)">
            <source srcset="../assets/images/hero/1024@1x.jpg" media="(max-width: 960px)">
            <source srcset="../assets/images/hero/1400@1x.jpg" media="(max-width: 3840px)">
            <img class="cover__image hero__cover-image" src="#" alt="">
        </picture>
    </div>
</template>

<script>
export default {
    name: "AppHero",

    mounted() {
        setTimeout(this.setScreenHeight, 100);
    },

    methods: {
        setScreenHeight() {
            document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
        }
    }
}
</script>

<style lang="scss">

.hero {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    height: calc(100vh - 140px);
    text-transform: uppercase;
    overflow: hidden;

    &__suptitle {
        margin: 0 0 30px;
        color: var(--color-black);
        font-size: var(--size-3);
    }

    &__title {
        color: var(--grey-dark);
        font-size: 46px;
    }

    &__cover-image {
        min-height: 750px;
    }

    @media all and (max-width: 1200px) {
        height: calc(100vh - 88px);

        &__suptitle {
            position: relative;
            display: inline-block;
            margin: 0 0 40px;
            font-size: var(--size-4);
            text-transform: none;

            &:after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                height: 2px;
                background: linear-gradient(
                        90deg,
                        rgba(255, 255, 255, .01) 0%,
                        rgba(0, 155, 244, 1) 50%,
                        rgba(255, 255, 255, 0.01) 100%
                );
            }
        }

        &__title {
            max-width: 780px;
            font-size: 42px;
            line-height: 1.3;

            span {
                font-weight: 400;
            }
        }
    }

    @media all and (max-width: 960px) {
        height: auto;
        min-height: 740px;

        &__cover-image {
            min-height: auto;
        }
    }

    @media all and (max-width: 600px) {
        height: calc(var(--app-height) - 60px);
        min-height: auto;

        &__cover-image {
            min-height: 750px;
        }

        &__suptitle {
            font-size: 11px;
        }

        &__title {
            font-size: var(--size-3);
            line-height: 1.4;

            br:not(:first-child) {
                display: none;
            }
        }
    }
}
</style>