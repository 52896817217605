<template>
    <footer class="app-footer">
        <div class="container">
            <div class="app-footer__columns">
                <div class="app-footer__column app-footer__column--1">
                    <div class="app-footer__logo">
                        <img src="./../assets/images/logo.svg" alt="PACR by MapHome">
                        <a class="app-footer__logo-link" href="https://maphome.ru" target="_blank"></a>
                    </div>

                    <p class="app-footer__notice">Профессиональное объединение загородных риелторов</p>

                    <nav class="app-footer__nav-primary">
                        <a class="app-footer__nav-primary-link" href="#">Правила сообщества</a>
                        <a class="app-footer__nav-primary-link" target="_blank" href="https://maphome.ru/page/user-agreement">
                            Пользовательское соглашение
                        </a>
                        <a class="app-footer__nav-primary-link" target="_blank" href="https://maphome.ru/page/gdpr">
                            Обработка персональных данных
                        </a>
                    </nav>
                </div>

                <div class="app-footer__column app-footer__column--2">
                    <div class="app-footer__columns app-footer__columns--2">
                        <div class="app-footer__column">
                            <a class="app-footer__tel" href="tel:79263338803">+7 926 333-88-03</a>

                            <nav class="app-footer__nav-secondary">
                                <a @click.prevent="callback" class="app-footer__nav-secondary-link app-footer__nav-secondary-link--decorated" href="#">
                                    Заказать звонок
                                </a>
                                <a class="app-footer__nav-secondary-link app-footer__nav-secondary-link--decorated" href="https://api.whatsapp.com/send?phone=79263338803">
                                    Написать в WhatsApp
                                </a>
                                <a class="app-footer__nav-secondary-link" href="/login.html">
                                    <svg class="app-footer__nav-secondary-icon" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <path d="M20.747 0h2.606c.1 0 .2.1.3.1 3.409.2 6.616 1.199 9.623 2.997 5.713 3.496 9.22 8.491 10.423 15.085.2.8.2 1.698.301 2.497v2.698c0 .1-.1.2-.1.3-.2 1.098-.3 2.297-.501 3.396-1.103 4.695-3.508 8.492-7.217 11.688a22.403 22.403 0 0 1-4.41 2.897c-4.91 2.398-10.023 2.998-15.335 1.599-5.011-1.299-9.12-4.096-12.227-8.292C1.002 30.37-.4 25.275.1 19.68c.501-4.595 2.305-8.69 5.413-12.187C8.92 3.696 13.13 1.299 18.24.4c.802-.2 1.604-.3 2.506-.4Zm14.834 37.163c.601-.6 1.203-1.199 1.804-1.898 4.61-5.495 6.114-11.788 4.21-18.681C38.787 6.194 28.564-.1 17.94 1.998 7.517 4.096.3 13.886 1.804 24.576c.601 4.595 2.606 8.491 5.813 11.788.702.7.702.7 1.504.2 0 0 .1-.1.2-.1 1.905-1.1 3.91-2.098 5.813-3.197.802-.4 1.103-1 1.103-1.898V29.67c0-.3-.1-.5-.2-.7-.903-1.398-1.704-2.797-2.105-4.395l-.301-.6c-.501-.5-.802-1.099-.802-1.798 0-1-.1-2.098 0-3.097 0-.5.3-.999.501-1.598.1-.2.2-.4.2-.6v-3.896c0-2.497 1.404-4.895 3.81-5.794 3.006-1.199 6.013-1.199 9.02-.2 2.907.999 4.41 3.297 4.41 6.394v3.596c0 .2.1.3.2.5.2.5.502.999.502 1.498.1 1 0 2.098 0 3.097 0 .999-.502 1.698-1.303 2.298-.2.1-.301.3-.401.5-.502.998-.802 1.997-1.404 2.896-.902 1.1-1.102 2.298-1.002 3.697.1.799.401 1.498 1.103 1.798 2.205 1.398 4.61 2.597 7.116 3.896Zm-1.403.999c-.1-.1-.301-.2-.401-.2-2.105-.999-4.21-2.098-6.214-3.097-1.403-.699-2.005-1.798-2.005-3.296V29.17c0-.2.1-.4.2-.5 1.203-1.498 2.005-3.096 2.607-4.895.1-.2.2-.4.4-.5.702-.199 1.003-.698 1.003-1.398V19.28c0-.4-.301-.7-.401-1.1-.2-.199-.3-.399-.3-.599v-4.395c0-2.098-1.103-3.796-3.008-4.496-2.506-.999-5.111-.999-7.617-.1-2.205.7-3.408 2.498-3.408 4.696v4.495c0 .2-.1.4-.2.5-.301.3-.501.7-.501 1.099v2.597c0 .5.2.9.6 1.199.101.1.302.3.302.5a15.378 15.378 0 0 0 2.405 4.995c.1.1.2.3.2.499v2.298c0 1.498-.6 2.597-2.004 3.396-1.904 1-3.809 2.098-5.713 3.097-.1 0-.3.1-.4.2 7.516 5.794 17.94 5.195 24.455 0Z"/>
                                    </svg>
                                    <span class="app-footer__nav-secondary-text">Личный кабинет</span>
                                </a>
                            </nav>
                        </div>

                        <div class="app-footer__column">
                            <div class="app-footer__widget app-footer__widget--subscribe">
                                <h4 class="app-footer__widget-title">Мы на YouTube</h4>

                                <div class="subscribe-box">
                                    <div class="subscribe-box__icon">
                                        <img width="80" height="80" src="https://maphome.ru/images/landing-open-sales/yt-logo.png" alt="">
                                    </div>

                                    <div>
                                        <div class="subscribe-box__title">ДомаДОМ - всё о загородной жизни...</div>
                                        <a href="https://www.youtube.com/c/%D0%94%D0%BE%D0%BC%D0%B0%D0%94%D0%BE%D0%BC%D0%B8%D0%BA?sub_confirmation=1" target="_blank" class="button button--subscribe">
                                            Подписаться
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <a href="https://api.whatsapp.com/send?phone=79263338803" class="app-footer__whatsapp-link"></a>
    </footer>
</template>

<script>
export default {
    name: "AppFooter",

    methods: {
        callback() {
            this.emitter.emit('modal.request:open');
        }
    }
}
</script>

<style lang="scss">

.app-footer {
    position: relative;
    background-color: #1F1F1F;
    padding: 60px 30px;
    overflow: hidden;

    &__columns {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &__column {
        flex-grow: 1;

        &--1 {
            flex: none;
            width: 37%;
        }
    }

    &__logo {
        position: relative;
        margin: 0 0 20px;
    }

    &__logo-link {
        position: absolute;
        width: 160px;
        height: 100%;
        left: 200px;
    }

    &__notice {
        margin: 0 0 40px;
        color: var(--color-white);
        max-width: 340px;
    }

    &__tel {
        display: block;
        margin: 70px 0 46px;
        color: var(--color-white);
        font-size: 34px;
    }

    &__nav-primary,
    &__nav-secondary {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
    }

    &__nav-primary-link,
    &__nav-secondary-link {
        display: flex;
        align-items: center;
        color: var(--grey-light);
        margin-bottom: 6px;

        &--decorated {
            text-decoration: underline;
            text-underline-offset: 4px;
        }

        &:hover path {
            fill: var(--color-primary);
        }
    }

    &__nav-secondary-icon {
        width: 22px;
        height: auto;
        margin-right: 8px;

        path{
            fill: var(--grey-light);
            transition: fill .3s;
        }
    }

    &__widget {
        &--subscribe {
            margin-top: 80px;
        }
    }

    &__widget-title {
        color: var(--color-white);
        margin: 0 0 20px;
    }

    &__whatsapp-link {
        position: fixed;
        bottom: 50px;
        right: 50px;
        width: 80px;
        height: 80px;
        background: #00bd45 url("./../assets/images/whatsap.svg") no-repeat;
        background-size: cover;
        box-shadow: 0 0 12px rgba(0, 0, 0, .4);
        border-radius: 99999px;
        visibility: hidden;
        z-index: 1;
    }

    @media all and (max-width: 1200px) {
        &__columns {
            &--2 {
                flex-flow: column nowrap;
            }
        }

        &__column {
            &--1 {
                width: 50%;
            }
        }

        &__tel {
            margin: 0 0 46px;
        }

        &__widget {
            &--subscribe {
                margin-top: 40px;
            }
        }
    }

    @media all and (max-width: 960px) {
        &__column {
            &--1 {
                width: 100%;
            }
        }

        &__tel {
            margin: 40px 0 46px;
        }

        &__whatsapp-link {
            bottom: 20px;
            right: 20px;
            width: 40px;
            height: 40px;
            visibility: visible;
        }
    }

    @media all and (max-width: 600px) {
        &__notice {
            max-width: 300px;
        }
    }
}

.subscribe-box {
    display: flex;
    align-items: flex-start;

    &__icon {
        flex: none;
        margin-right: 12px;
        width: 64px;
        height: 64px;
    }

    &__image {
        border-radius: 99999px;
    }

    &__title {
        margin: 0 0 12px;
        color: #ABB1B8;
        font-size: 14px;
        max-width: 160px;
    }
}
</style>