<template>
    <section class="section section--dark section--medium benefits">
        <div class="container">
            <div class="benefits__columns">
                <div class="benefits__column benefits__column--full-width">
                    <h3 class="title title--primary benefits__title">Преимущества</h3>
                </div>

                <div class="benefits__column benefits__column--image">
                    <picture class="benefits__picture">
                        <source media="(max-width:960px)" srcset="./../assets/images/benefits/768@1x.jpg">
                        <img class="benefits__image" src="./../assets/images/benefits/1400@1x.jpg" alt="">
                    </picture>
                </div>

                <div class="benefits__column benefits__column--content">
                    <ul class="benefits__list">
                        <li class="benefits__list-item">Быстрая коммуникация с коллегами</li>
                        <li class="benefits__list-item">Административная помощь в разрешении спорных вопросов</li>
                        <li class="benefits__list-item">Коллективная помощь в обслуживании запросов от покупателей</li>
                        <li class="benefits__list-item">Коллективное формирование перечня лиц с недобросовестным поведением - черного списка: риелторы | собственники | покупатели</li>
                        <li class="benefits__list-item">Дружественный корпортивный дух, деловые встречи для обсуждений, вечеринки</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AppBenefits"
}
</script>

<style lang="scss">

.benefits {
    color: var(--color-white);

    &__title {
        margin: 0 0 80px;
    }

    &__columns {
        display: flex;
        flex-flow: row wrap;
    }

    &__column {

        &--full-width {
            flex: 0 0 100%;
        }

        &--image {
            flex: 0 0 400px;
        }

        &--content {
            flex: 0 0 calc(100% - 400px);
            padding-left: 60px;
        }
    }

    &__image {
        border-radius: 17px;
    }

    &__list {
        list-style-type: square;
    }

    &__list-item {
        margin: 0 0 10px;
    }

    @media all and (max-width: 960px) {
        padding-top: 0 !important;

        &__title {
            margin: 60px 0;
        }

        &__columns {
            flex-flow: column nowrap;
        }

        &__column {
            order: 2;

            &--image {
                order: 1;
            }

            &--content {
                flex: none;
                padding-left: 20px;
            }
        }

        &__picture {
            display: block;
            margin-left: calc(-50vw + 50%);
            margin-right: calc(-50vw + 50%);
        }

        &__image {
            width: 100%;
            border-radius: 0;
        }
    }

    @media all and (max-width: 600px) {

        &__title {
            margin: 60px 0 40px;
        }

        &__column {
            &--image {
                flex: auto;
            }
        }
    }
}
</style>