<template>
    <div class="app">
        <AppHeader></AppHeader>

        <div class="app__sections">
            <AppHero></AppHero>

            <AppCounters id="counters"></AppCounters>

            <AppMission></AppMission>

            <AppAbout id="about"></AppAbout>

            <AppPartners id="partners"></AppPartners>

            <AppBenefits id="benefits"></AppBenefits>

            <AppMap id="map"></AppMap>

            <AppCabinetInfo id="cabinet-info"></AppCabinetInfo>

            <AppCabinet id="cabinet"></AppCabinet>
        </div>

        <AppFooter></AppFooter>

        <AppModal name="request">
            <template v-slot:title>
                <h2>Оставьте свой номер телефона. <br>Наш менеджер свяжется с Вами.</h2>
            </template>

            <AppForm></AppForm>
        </AppModal>

        <AppModal name="success">
            <AppFormSent></AppFormSent>
        </AppModal>

        <a href="https://api.whatsapp.com/send?phone=79263338803" class="app__whatsapp-link"></a>
    </div>
</template>

<script>

import AppHeader from "@/components/Header.vue";
import AppHero from "@/sections/Hero.vue";
import AppCounters from "@/sections/Counters.vue";
import AppFooter from "@/components/Footer.vue";
import AppMission from "@/sections/Mission.vue";
import AppBenefits from "@/sections/Benefits.vue";
import AppAbout from "@/sections/About.vue";
import AppPartners from "@/sections/Partners.vue";
import AppCabinet from "@/sections/Cabinet.vue";
import AppCabinetInfo from "@/sections/CabinetInfo.vue";
import AppModal from "@/components/Modal.vue";
import AppForm from "@/components/Form.vue";
import AppMap from "@/sections/Map.vue";
import AppFormSent from "@/components/FormSent.vue";

export default {
    name: 'App',
    components: {
        AppFormSent,
        AppMap,
        AppForm,
        AppModal,
        AppCabinetInfo,
        AppCabinet,
        AppPartners,
        AppAbout,
        AppBenefits,
        AppMission,
        AppFooter,
        AppCounters,
        AppHero,
        AppHeader

    }
}
</script>

<style lang="scss">

@import "assets/scss/app";

</style>
