<template>
    <div class="section section--medium mission">
        <div class="container">
            <h3 class="title mission__title">Миссия сообщества</h3>

            <div class="mission__logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="185" height="48" fill="none" viewBox="0 0 185 48">
                    <path fill="#fff" d="M39.4 23.1c.9-1.3 1.4-2.9 1.4-4.6v-3c0-3.6-.3-6.4-.9-8.3-.6-1.9-2.1-3.5-4.6-4.6-2.5-1.1-6.5-1.8-12-1.8H0v45.6h2v-19h28.7c1.8 0 3.5-.4 5.1-1.1 1.5-.8 2.7-1.8 3.6-3.2ZM2 25.5V12.3h27.2c2.8 0 5.2.5 7 1.5 1.9 1 2.8 2.7 2.8 4.9 0 2.1-.8 3.8-2.5 5-1.7 1.2-3.6 1.8-5.8 1.8H2ZM55 .9 33 46.5h12l7.8-16.3h25.9l7.6 16.3h2.1L67 .9H55Zm-1.3 27.4L65.9 3.1l11.9 25.2H53.7Zm43.9-15.2c3.4-1.1 7.1-1.7 11.1-1.7h3.5c4.4 0 8.4.7 12.1 2 3.6 1.3 6.4 3.1 8.1 5.3l3-.6c-.8-3.5-2.4-6.6-4.6-9.4-2.3-2.7-5-4.9-8.3-6.4C119.2.8 115.6 0 111.8 0h-2.5c-4.6 0-8.8 1.1-12.5 3.2-3.7 2.1-6.7 5-8.8 8.6-2.1 3.6-3.2 7.6-3.2 12 0 4.3 1.1 8.3 3.2 11.9 2.1 3.6 5 6.5 8.8 8.6 3.7 2.1 7.9 3.2 12.5 3.2h2.5c3.9 0 7.5-.8 10.8-2.3 3.3-1.6 6.1-3.7 8.3-6.4 2.2-2.7 3.8-5.8 4.6-9.3l-3-.6c-1.8 2.2-4.5 3.9-8.1 5.3-3.6 1.3-7.7 2-12.1 2h-3.5c-4 0-7.7-.6-11.1-1.7-3.4-1.1-6.1-2.6-8.1-4.5s-3-3.9-3-6.2c0-2.3 1-4.3 3-6.2 2-1.9 4.7-3.4 8-4.5ZM180 44.8c-1.2-.3-2.1-1.5-3-2.9-.9-1.4-1.9-3-2.8-4.9l-4.7-9.7h.5c2.8 0 5.2-.8 7.1-2.3 1.9-1.5 2.9-3.7 3.2-6.5 0-4.8-.4-8.4-1.2-10.7-.8-2.4-2.4-4.1-4.9-5.2-2.5-1.1-6.3-1.6-11.4-1.6h-21.5V.9h-1.9v45.6h1.9V27.4h26.2l2 4.3c.7 1.2 1.6 3 2.7 5.3 1.1 2.4 2.1 4.3 3 5.7.9 1.4 2 2.7 3.4 3.6 1.3.7 5.5.3 5.5.3v-1.7c-.1-.1-2.9.2-4.1-.1Zm-38.8-19.3V12.3h27.2c2.9 0 5.2.5 7.1 1.5 1.8 1 2.8 2.6 2.8 4.8 0 2.1-.8 3.8-2.5 5-1.7 1.2-3.6 1.8-5.9 1.8h-28.7v.1Z"/>
                </svg>
            </div>

            <div class="mission__content">
                <p>Объединение всех загородных экспертов с целью масштабирования рынка недвижимости, открытости и прозрачности. </p>
                <p>Благодаря партнерскому взаимодействию мы увеличиваем эффективность продажи: коллеги предлагают свои эксклюзивные объекты и делятся комиссией с другими брокерами.</p>
            </div>
        </div>
    </div>

    <div class="section section--medium capabilities">
        <div class="container">
            <div class="capabilities__content">
                <b class="capabilities__label">Благодаря объединению:</b>

                <ul class="capabilities__list">
                    <li class="capabilities__list-item">Брокер-туры стали проводиться на 50% процентов чаще.</li>
                    <li class="capabilities__list-item">Партнёрские показы специалистов нашей агентской сети увеличились на 40%.</li>
                    <li class="capabilities__list-item">Эксклюзивных объектов стало на 60% больше.</li>
                </ul>

                <b class="capabilities__label capabilities__label--primary capabilities__label--has-image">
                    Продажа недвижимости через профессиональное сообщество  быстрее и на максимально выгодных условиях
                </b>

                <b class="capabilities__label">Застройщики и девелоперы активно используют наши возможности:</b>
                <ul class="capabilities__list">
                    <li class="capabilities__list-item">аналитику и исследование рынка</li>
                    <li class="capabilities__list-item">посторение отдела продаж <a target="_blank" href="https://sale.maphome.ru">подробней</a></li>
                    <li class="capabilities__list-item">знания и опыт в востребованности архитектурного стиля и образа загородной жизни <a target="_blank" href="https://cada.info">подробней</a></li>
                </ul>

                <b class="capabilities__label capabilities__label--primary">Будьте открыты рынку недвижимости!</b>
            </div>
        </div>

        <picture class="cover">
            <source srcset="../assets/images/capabilities/1400@1x.jpg" media="(max-width: 3840px)">
            <img class="cover__image" src="#" alt="">
        </picture>
    </div>

    <section class="section section--medium mission-cta">
        <div class="container">
            <h3 class="title mission-cta__title">Задействовать сообщество</h3>
            <AppForm class="mission-cta__form"></AppForm>
        </div>
    </section>
</template>

<script>

import AppForm from "@/components/Form.vue";

export default {
    name: "AppMission",
    components: {AppForm}
}
</script>

<style lang="scss">

.mission {
    background-color: var(--color-primary);
    color: var(--color-white);

    &__title {
        margin: 0 0 30px;
    }

    &__logo {
        margin: 0 0 60px;
    }

    &__content {
        line-height: 1.8;
    }

    @media all and (max-width: 1200px) {
        &__content p {
            display: inline;
        }
    }
}

.capabilities {
    position: relative;

    &__content {
        max-width: 700px;
        color: var(--grey-dark);
    }

    &__label {
        display: block;
        margin: 0 0 20px;

        &--primary {
            color: var(--color-primary);
        }

        &--has-image {
            margin: 0 0 40px;
        }
    }

    &__list {
        margin: 0 0 40px 20px;
        padding: 0;
        list-style-type: square;
    }

    &__list-item a {
        color: var(--grey);
        text-decoration: underline;
    }

    @media all and (max-width: 960px) {
        .cover {
            display: none;
        }
    }

    @media all and (max-width: 600px) {
        &__label {

            &--has-image {
                margin: 0 -30px 40px;
                padding: 80px 50% 140px 30px;
                background: url("./../assets/images/capabilities/375@1.5x.jpg") no-repeat center;
                background-size: cover;
            }
        }
    }
}

.mission-cta {

    &__title {
        margin: 0 0 60px;
    }

    &__form {
        max-width: 620px;
    }

    @media all and (max-width: 1200px) {
        padding-top: 0 !important;
    }
}

</style>