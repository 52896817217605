<template>
    <div class="modal modal__active" v-if="active">
        <div class="modal__background" @click="close"></div>

        <div class="modal__content">
            <div class="modal__close" @click="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 48 48">
                    <path fill="#ABB1B8" d="M13.412 12L36 34.588 34.588 36 12 13.412 13.412 12z"/>
                    <path fill="#ABB1B8" d="M36 13.412L13.412 36 12 34.588 34.588 12 36 13.412z"/>
                </svg>
            </div>

            <div class="modal__box">
                <div class="modal__title">
                    <slot name="title"></slot>
                </div>

                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppModal",

    props: {
        title: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            active: false
        }
    },

    mounted() {
        this.emitter.on(`modal:close`, this.close);
        this.emitter.on(`modal.${this.name}:open`, this.open);
    },

    methods: {
        close() {
            this.active = false;
        },

        open() {
            this.active = true;
        }
    }
}
</script>

<style lang="scss">

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    &__background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .8);
        cursor: pointer;
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
    }

    &__content {
        position: relative;
        max-width: 640px;
        max-height: 100%;
        border-radius: 4px;
        overflow: auto;
        z-index: 1;
    }

    &__box {
        background-color: var(--color-white);
        padding: 30px;
    }

    &__title {
        font-size: var(--size-3);
        padding-right: 60px;
        margin: 0 0 40px;
    }

    @media all and (max-width: 600px) {
        max-width: calc(100% - 60px);
        margin: 0 30px;
    }
}
</style>