<template>
    <form class="request" action="#" @submit.prevent="submit" method="POST">
        <div class="field field--grouped">
            <div class="control control--expanded">
                <input v-maska="form.phone.placeholders.focus" required class="input" @focus="focus" @blur="blur"
                       type="tel" pattern=".{18}" :placeholder="form.phone.placeholders.active" v-model="form.phone.value">
            </div>

            <div class="control">
                <button type="submit" :class="['button button--primary', {'button--loading': form.loading}]">
                    {{ button }}
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import {maska} from 'maska'

export default {
    name: "AppForm",

    directives: {
        maska
    },

    props: {
        button: {
            type: String,
            default: 'Отправить'
        },

        subject: {
            type: String,
            default: 'Заявка с сайта PACR'
        }
    },

    data() {
        return {
            form: {
                loading: false,
                phone: {
                    value: '',
                    placeholders: {
                        active: 'Номер телефона',
                        focus: '+7 (XXX) XXX-XX-XX',
                        blur: 'Номер телефона',
                    }
                },
            }
        }
    },

    methods: {
        focus() {
            this.form.phone.placeholders.active = this.form.phone.placeholders.focus;
        },

        blur() {
            this.form.phone.placeholders.active = this.form.phone.placeholders.blur;
        },

        submit() {
            this.form.loading = true;

            const data = new FormData();
            data.append('phone', this.form.phone.value);
            data.append('subject', this.subject);

            const reset = () => {
                this.form.phone.value = '';
                this.form.loading = false;
            }

            fetch('/request.php', {method: 'POST', body: data})
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }

                    return response.json();
                })
                .then(() => {
                    reset();
                    this.emitter.emit('modal:close');
                    this.emitter.emit('modal.success:open');
                })
                .catch(reset);
        }
    }
}
</script>

<style lang="scss">

</style>