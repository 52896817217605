<template>
    <section @click="replace" class="section section--medium partners">
        <div class="container">
            <h3 class="title partners__title">Участники</h3>

            <!--<transition-group name="fade" tag="div" class="partners__list">
                <div class="partners__list-item" :key="logo.id" v-for="logo in logos.current">
                    <img class="partners__list-image" :src="src(logo.file)" alt="">
                </div>
            </transition-group>-->

            <swiper class="partners__slides" :modules="modules" loop :speed="2000" :autoplay="{delay: 3000}"
                    effect="coverflow"
                    :preventInteractionOnTransition="true"
                    :simulateTouch="false"
                    :creativeEffect="{prev: {translate: [0, 0, -400]}, next: {translate: ['100%', 0, 0]}}"
            >
                <swiper-slide :key="slide" v-for="slide in logos.all" class="partners__list">
                    <div class="partners__list-item" :key="item" v-for="item in slide">
                        <img class="partners__list-image" :src="src(item.file)" alt="">
                    </div>
                </swiper-slide>
            </swiper>

            <div class="partners__columns">
                <div class="partners__column">
                    <b class="partners__label">Работаете в компании или частным образом?</b>
                </div>

                <div class="partners__column">
                    <h3 class="title">Вступите в наше сообщество</h3>
                </div>

                <div class="partners__column">
                    <a href="/register.html" class="button partners__button">Заполнить анкету</a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

// import Swiper core and required modules
import {Autoplay, EffectCoverflow} from 'swiper';

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-coverflow';

export default {
    name: "AppPartners",

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            modules: [Autoplay, EffectCoverflow],
            logos: {
                all: [],
                current: []
            }
        }
    },

    mounted() {
        // TODO: Refactor this.
        this.logos.all = [
            this.images(12),
            this.images(24).slice(12),
            this.images(30).slice(24).concat(this.images(6))
        ];

        // this.logos.all = (Array.apply(null, Array(30))).map((item, index) => {
        //     return {id: (index + 1), file: (index + 1)  +'.jpg'}
        // });

        //this.logos.current = this.logos.all.slice(0, 12);

        //setInterval(this.replace, 3500);
    },

    methods: {
        images(length) {
            return (Array.apply(null, Array(length))).map((item, index) => {
                return {
                    id: index++,
                    file: index++ + '.jpg'
                }
            });
        },

        src(logo) {
            return require('@/assets/images/logos/' + logo);
        },

        // replace() {
        //     const last = this.logos.current[this.logos.current.length - 1];
        //     const index = this.logos.all.findIndex(i => i.id === last.id) + 1;
        //     const next = index < this.logos.all.length ? this.logos.all[index] : this.logos.all[0];
        //
        //     this.logos.current.shift();
        //     this.logos.current.push(next);
        // }
    }
}
</script>

<style lang="scss">

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.partners {

    &__title {
        margin: 0 0 100px;
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        background-color: var(--color-white);
    }

    &__list-item {
        margin: 5px 0;
        transition: all .5s;
    }

    &__columns {
        display: flex;
        margin: 140px 0 80px;
    }

    &__column {
        max-width: 240px;

        &:not(:last-child) {
            margin: 0 100px 0 0;
        }
    }

    &__label {
        color: var(--color-primary);
    }

    @media all and (max-width: 1200px) {
        &__title {
            margin: 0 0 80px;
        }

        &__list-item {
            max-width: 140px;
        }

        &__columns {
            margin: 100px 0 20px;
        }
    }

    @media all and (max-width: 960px) {
        &__list-item {
            max-width: 33.333333%;
        }

        &__columns {
            flex-flow: column nowrap;
            align-items: center;
            text-align: center;
        }

        &__column:not(:last-child) {
            margin: 0 0 40px;
        }
    }

    @media all and (max-width: 600px) {
        &__list-item {
            max-width: 115px;
        }

        &__columns {
            display: none;
        }
    }
}
</style>