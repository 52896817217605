<template>
    <section class="section section--medium cabinet">
        <div class="container">
            <h3 class="title cabinet__title">Инструментарий</h3>
            <p class="cabinet__subtitle">Личного кабинета</p>

            <div class="cabinet__list">
                <div class="cabinet__item" :key="index" v-for="(item, index) in items">
                    <div :class="'cabinet__item-icon cabinet__item-icon--' + item.slug"></div>
                    <div class="cabinet__item-content">
                        <div class="cabinet__item-title">{{ item.title }}</div>
                        <div class="cabinet__item-text">{{ item.text }}</div>
                    </div>
                </div>
            </div>

            <div class="cabinet__request">
                <div class="cabinet__request-headings">
                    <div class="cabinet__request-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" fill="none" viewBox="0 0 58 58">
                            <path fill="#231F20" fill-rule="evenodd" d="M28.8 2C13.999 2 2 13.999 2 28.8s11.999 26.8 26.8 26.8 26.8-11.999 26.8-26.8S43.601 2 28.8 2ZM0 28.8C0 12.894 12.894 0 28.8 0s28.8 12.894 28.8 28.8-12.894 28.8-28.8 28.8S0 44.706 0 28.8Z" clip-rule="evenodd"/>
                            <path fill="#3CC" fill-rule="evenodd" d="M28.8 11.1a2 2 0 0 1 2 2v31.3a2 2 0 1 1-4 0V13.1a2 2 0 0 1 2-2Z" clip-rule="evenodd"/>
                            <path fill="#3CC" fill-rule="evenodd" d="M11.1 28.8a2 2 0 0 1 2-2h31.3a2 2 0 1 1 0 4H13.1a2 2 0 0 1-2-2Z" clip-rule="evenodd"/>
                        </svg>
                    </div>
                    <h3 class="cabinet__request-title">Добавить партнёрскую программу</h3>
                </div>

                <div class="cabinet__request-form">
                    <AppForm button="Предложить условия"></AppForm>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import AppForm from "@/components/Form.vue";

export default {
    name: "AppCabinet",
    components: {AppForm},

    data() {
        return {
            items: [{
                slug: 'user-card',
                title: 'База недвижимости',
                text: 'Предоставление скидки в размере 70% на доступ к базе собственников'
            }, {
                slug: 'location',
                title: 'Точечный поиск',
                text: 'Оперативно поможем найти контакт продавца по кадастровому номеру или точному адресу'
            }, {
                slug: 'money',
                title: 'Ипотека',
                text: 'Предоставляем лучшие условия по ипотеке для клиентов нашего сообщества с дополнительным бонусом для агента'
            }, {
                slug: 'pencil',
                title: 'БТИ',
                text: 'Особое внимание и оперативность от регулирующего органа при подаче документов нашим специалистам'
            }, {
                slug: 'papers',
                title: 'Выписи',
                text: 'Оперативное получение информации по объекту'
            }, {
                slug: 'house',
                title: 'Страхование',
                text: 'Предоставляем лучшие условия по страхованию для клиентов нашего сообщества с дополнительным бонусом для агента'
            }, {
                slug: 'digger',
                title: 'Строительные услуги',
                text: 'Предоставляем лучшие условия по строительству для клиентов нашего сообщества с дополнительным бонусом для агента'
            }, {
                slug: 'handshake',
                title: 'Партнёрская программа',
                text: 'Работай на покупателя - не теряй заработок от собственника'
            }]
        }
    }
}
</script>

<style lang="scss">

.cabinet {
    overflow: hidden;

    &__subtitle {
        margin: 0 0 40px;
    }

    &__item {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 6px 0;
        cursor: default;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        $icon: & + '-icon';

        &:not(:last-child) {
            margin: 0 0 20px;
        }

        &:hover {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #F1F1F2 80%, #F1F1F2 100%);
            border-top: 1px solid var(--grey-light);
            border-bottom: 1px solid var(--grey-light);

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: -1px;
                left: 0;
                width: 999px;
                height: calc(100% + 2px);
                background-color: #F1F1F2;
                border-top: 1px solid var(--grey-light);
                border-bottom: 1px solid var(--grey-light);
            }

            &:before {
                left: -999px;
                background-color: var(--color-white);
            }

            &:after {
                left: 100%;
            }

            #{$icon}--user-card { background: url("./../assets/images/icons/user-card.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--location { background: url("./../assets/images/icons/location.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--money { background: url("./../assets/images/icons/money.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--pencil { background: url("./../assets/images/icons/pencil.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--papers { background: url("./../assets/images/icons/papers.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--house { background: url("./../assets/images/icons/house.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--digger { background: url("./../assets/images/icons/digger.gif") no-repeat center; background-size: 86px 86px; }
            #{$icon}--handshake { background: url("./../assets/images/icons/handshake.gif") no-repeat center; background-size: 86px 86px; }
        }
    }

    &__item-icon {
        flex: none;
        margin: 0 30px 0 0;
        width: 86px;
        height: 86px;

        &--user-card { background: url("./../assets/images/icons/user-card.png") no-repeat center; background-size: 70px; }
        &--location { background: url("./../assets/images/icons/location.png") no-repeat center; background-size: 70px; }
        &--money { background: url("./../assets/images/icons/money.png") no-repeat center; background-size: 70px; }
        &--pencil { background: url("./../assets/images/icons/pencil.png") no-repeat center; background-size: 70px; }
        &--papers { background: url("./../assets/images/icons/papers.png") no-repeat center; background-size: 70px; }
        &--house { background: url("./../assets/images/icons/house.png") no-repeat center; background-size: 70px; }
        &--digger { background: url("./../assets/images/icons/digger.png") no-repeat center; background-size: 70px; }
        &--handshake { background: url("./../assets/images/icons/handshake.png") no-repeat center; background-size: 70px; }
    }

    &__item-content {
        display: flex;
        align-items: center;
        flex: none;
        width: calc(100% - 106px);
    }

    &__item-title {
        flex: none;
        color: var(--color-primary);
        font-size: var(--size-4);
        font-weight: 700;
        text-transform: uppercase;
        width: 400px;
    }

    &__item-text {
        flex: none;
        width: calc(100% - 400px);
    }

    &__request {
        display: flex;
        align-items: flex-end;
        margin: 80px 0 0;
    }

    &__request-headings {
        display: flex;
        align-items: flex-end;
    }

    &__request-icon {
        display: flex;
        margin: 0 40px 0 15px;
    }

    &__request-title {
        max-width: 286px;
        text-transform: uppercase;
        margin: 0 40px 0 0;
    }

    &__request-form {
        flex-grow: 1;
        max-width: 640px;
    }

    @media all and (max-width: 960px) {
        &__item {
            padding: 10px 0;

            &:hover {
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100px, rgba(241, 241, 242, 1) 100%);
            }
        }

        &__item-content {
            flex-flow: column nowrap;
            align-items: flex-start;
        }

        &__item-text {
            flex-grow: 1;
            width: auto;
        }

        &__request {
            flex-flow: column nowrap;
            align-items: flex-start;
        }

        &__request-form {
            margin-top: 40px;
            width: 100%;
        }

    }

    @media all and (max-width: 600px) {
        &__item-content {
            width: auto;
            flex: 1;
        }

        &__item-title {
            width: auto;
            font-size: var(--size-6);
            margin: 0 0 10px;
        }

        &__request-title {
            font-size: var(--size-6);
        }
    }
}
</style>